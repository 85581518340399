<template>
	<div>
		<!-- 调账头部表单 -->
		<el-form
			ref="queryForm"
			class="query-box"
			:model="queryParams"
			label-width="114px"
		>
			<el-row :gutter="20">
				<el-col :span="6">
					<el-form-item
						label="代理商/机构编号"
						prop="agentNo"
					>
						<el-input
							v-model="queryParams.agentNo"
							clearable
							placeholder="请输入代理商/机构编号"
							size="small"
							@keyup.enter.native="handleQuery"
						/>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="上游通道" prop="channelNo">
						<el-select
							v-model="queryParams.channelNo"
							placeholder="请选择上游通道"
							style="width: 100%"
							clearable
						>
							<el-option
								v-for="item in acqChannelList"
								:label="item.name"
								:value="item.channelNo"
								:key="item.channelNo"
							>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item
						label="调账类型"
						prop="adjustmentType"
					>
						<el-select
							v-model="queryParams.adjustmentType"
							placeholder="请选择调账类型"
							style="width: 100%"
							clearable
						>
							<el-option
								v-for="item in adjustmentType"
								:key="item.id"
								:label="item.name"
								:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="账户类型" prop="type">
						<el-select
							v-model="queryParams.accountType"
							placeholder="请选择账户类型"
							clearable
							size="small"
							style="width: 100%"
						>
							<!-- <el-option label="全部" value="" /> -->
							<el-option
								v-for="item in accountType"
								:key="item.id"
								:label="item.name"
								:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="6">
					<el-form-item label="调整日期" prop="name">
						<el-date-picker
							size="small"
							v-model="createDateRange"
							type="daterange"
							@change="dateChange"
							range-separator="-"
							value-format="yyyy-MM-dd"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							style="width: 100%;"
						>
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>	
		</el-form>
		<!-- 按钮 -->
		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button
					type="primary"
					icon="el-icon-search"
					size="mini"
					@click="handleQuery"
					>查询</el-button
				>
				<el-button
					icon="el-icon-refresh"
					size="mini"
					@click="handleResetQuery"
					>重置</el-button
				>
				<el-button
					icon="el-icon-download"
					size="mini"
					@click="handleExport"
					>导出</el-button
				>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import { FinancialToolsApi, TerminalApi } from "@/api";
export default {
	name: "ToolBar",
	data() {
		return {
			// 表单数据
			queryParams: {},
			createDateRange: [], //创建时间
			updateDateRange: [], //完成时间
			acqChannelList: [], //上游通道
			showCustomForm: false,
			// 调账类型
			adjustmentType: [
				{
					id: "1",
					value: "ADJUSTMENT_ADD",
					name: "调增"
				},
				{
					id: "2",
					value: "ADJUSTMENT_SUBTRACT",
					name: "调减"
				}
			],
			// 账户类型
			accountType: [
				{
					id: "1",
					value: "POS_PROFIT",
					name: "分润账户"
				},
				{
					id: "2",
					value: "ACTIVE_PROFIT",
					name: "返现账户"
				},
				{
					id: "3",
					value: "OTHER_PROFIT",
					name: "其他账户"
				},
				{
					id: "4",
					value: "FLOW_PROFIT",
					name: "流量卡账户"
				}
			],
			pageNo: 1,
			pageSize: 10,
			total: 0,
			// 日期6个月区间数据
			isRestart: false,
			pickerMinDate: "",
			pickerOptions: {
				onPick: ({ maxDate, minDate }) => {
					this.isRestart = false;
					this.pickerMinDate = minDate.getTime();
					if (maxDate) {
						this.pickerMinDate = "";
					}
				},
				disabledDate: time => {
					if (this.pickerMinDate !== "") {
						// 几个月 * 1个月30天
						const one = 6 * 30 * 24 * 3600 * 1000;
						const minTime = this.pickerMinDate - one;
						const maxTime = this.pickerMinDate + one;
						return (
							time.getTime() < minTime || time.getTime() > maxTime
						);
					}
				}
			}
		};
	},
	async create() {},
	async mounted() {
		//上游通道列表
		const result = await TerminalApi.acqChannel.listAllChannel();
		this.acqChannelList = (result && result.data) || [];
		// 打印
		console.log("this.dicts----->", this.dicts);
	},
	methods: {
		// 查询事件
		async handleQuery() {
			// console.log("this.queryParams----->", this.queryParams);
			let result = await FinancialToolsApi.agentAccount.getAgentAccountList(
				this.pageNo,
				this.pageSize,
				this.queryParams
			);
			console.log("getAgentAccountList----->189", result);
			this.commitChange();
		},
		// 重置事件
		handleResetQuery() {
			this.queryParams = {}; //调账数据
			this.createDateRange = [];
			this.updateDateRange = []; //完成时间
			this.commitChange();
		},
		//导出功能
		handleExport() {
			this.$confirm("确定导出调账记录吗", "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(async () => {
				this.dealWithQueryParams();
				// 获取导出代理商调账记录
				let result = await FinancialToolsApi.agentAccount.exportAgentAccountList(
					this.queryParams
				);
				console.log("exportAgentAccountList----->216", result);
				if (result.success) {
					// 下载导出文件
					this.downloadFile(result.data);
				}
			});
		},
		// // 日期事件
		// dealWithQueryParams() {
		// 	// 开始创建时间,截至创建时间
		// 	let [startCreateTime, endCreateTime] = this.createDateRange;
		// 	this.queryParams.startCreateTime =
		// 		startCreateTime && startCreateTime + " 00:00:00";
		// 	this.queryParams.endCreateTime =
		// 		endCreateTime && endCreateTime + " 23:59:59";
		// 	// 更新时间
		// 	let [startUpdateTime, endUpdateTime] = this.updateDateRange;
		// 	this.queryParams.startUpdateTime =
		// 		startUpdateTime && startUpdateTime + " 00:00:00";
		// 	this.queryParams.endUpdateTime =
		// 		endUpdateTime && endUpdateTime + " 23:59:59";
		// },
		// 日期事件
		dealWithQueryParams() {
			console.log("dealWithQueryParams----->", this.createDateRange);
			// 这里要重置为空数组，判断方法再改下null或空数组
			if (!this.createDateRange) {
				this.createDateRange = [];
			}
			// 开始创建时间,截至创建时间
			let [startCreateTime, endCreateTime] = this.createDateRange;
			this.queryParams.startCreateTime =
				startCreateTime && startCreateTime + " 00:00:00";
			this.queryParams.endCreateTime =
				endCreateTime && endCreateTime + " 23:59:59";
		},
		// 查询提交事件
		commitChange() {
			// 调用日期事件
			this.dealWithQueryParams();
			// 展开保存表单数据--浅拷贝
			let params = { ...this.queryParams };
			// 传递事件携带参数给父组件

			this.$emit("on-change", params);
		},
		// 触发日期事件
		dateChange(val) {
			// 判断没有选中数据把transTime赋值为空
			if (!val) {
				this.queryParams.transTime = "";
			} else {
				// 把调整日期的对象数据转换为JSON字符串，用逗号拼接起来。
				// 再将字符串数据解析为对象————深拷贝
				this.queryParams.transTime = JSON.parse(
					JSON.stringify(val)
				).join(",");
				console.log("this.queryParams----->182", this.queryParams);
			}
		}
	}
};
</script>

<style scoped></style>
