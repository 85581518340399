<template>
	<!-- 表格组件 -->
	<div>
		<!-- <div style="padding:10px 0">汇总： {{ totalIncome }}元</div> -->
		<el-table stripe v-loading="loading" :data="dataList">
			<el-table-column
				label="代理商/机构编号"
				prop="agentNo"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="上游通道"
				prop="channelNo"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{ scope.row.channelNo | channelFM }}
				</template>
			</el-table-column>
			<el-table-column
				label="调账类型"
				prop="adjustmentType"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{
						dictTranslate(
							scope.row.adjustmentType,
							dicts.ADJUSTMENT_TYPE
						)
					}}
				</template>
			</el-table-column>
			<el-table-column
				label="账户类型"
				prop="accountType"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{
						dictTranslate(scope.row.accountType, dicts.ACCOUNT_TYPE)
					}}
				</template>
			</el-table-column>
			<el-table-column
				label="调整金额"
				prop="changeMoney"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="调整前余额"
				prop="beforeMoney"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="调整后余额"
				prop="afterMoney"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="备注"
				prop="remark"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="调整日期"
				prop="createTime"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
		</el-table>
		<!-- 分页 -->
		<Pagination
			v-show="total > 0"
			:total="total"
			:page.sync="pageNo"
			:limit.sync="pageSize"
			@pagination="getList"
		/>
	</div>
</template>
<script>
import { FinancialToolsApi, sumList } from "@/api";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: function() {
				return {};
			}
		},
		queryParams: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	data() {
		return {
			pageNo: 1,
			pageSize: 10,
			loading: false,
			total: 0,
			dataList: [], // 表格数据
			selectRow: {},
			showCustomForm: false,
			isAdd: false,
			totalIncome: 0 //总金额
		};
	},
	mounted() {},
	created() {
		// this.getList();
	},
	watch: {
		queryParams: function() {
			this.pageNo = 1;
			this.getList();
		}
	},
	methods: {
		// 获取列表
		async getList() {
			this.loading = true;
			// 账户汇总
			FinancialToolsApi.agentAccount
				.sumList(this.queryParams)
				.then(res => {
					if (res.success) {
						this.totalIncome = res.data.availableBalance;
					}
				});
			// 获取调账表格数据
			let result = await FinancialToolsApi.agentAccount.getAgentAccountList(
				this.pageNo,
				this.pageSize,
				this.queryParams
			);
			console.log("getAgentAccountList----->153", result.data);
			this.loading = false;
			this.total = result.count || 0;
			this.dataList = result.data || [];
		},
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			this.getList();
		},
		handleStatusChange(row) {
			let text = row.status === "DISABLE" ? "禁用" : "启用";
			this.$confirm("是否确定" + text + "?", "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(async () => {
					let result = await FinancialToolsApi.agentAccount.edit(
						row.accountNo,
						{
							state: row.state
						}
					);
					if (result.success) {
						this.getList();
					}
				})
				.catch(() => this.getList());
		},
		async handleUpdate(row) {
			this.showCustomForm = true;
			this.selectRow = row;
			this.isAdd = false;
		},
		handleDelete(row) {
			this.$confirm(
				"是否确定删除账户编号为" + row.accountNo + "的数据项}?",
				"警告",
				{
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}
			).then(async () => {
				let result = await FinancialToolsApi.agentAccount.remove(
					row.accountNo
				);
				if (result.success) {
					this.Message.success("删除成功");
					this.getList();
				}
			});
		}
	}
};
</script>

<style scoped></style>
